import React,{useState} from 'react'
import './nav.scss';
import { IoHome } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import { FaAlignJustify } from "react-icons/fa";
import { FaCommentAlt } from "react-icons/fa";
import { TbHexagonLetterP } from "react-icons/tb";


const Nav = () => {
  const [activNav, setAvtiveNav] = useState("#")

  return (
    <nav>
      <a href='#' onClick={() => setAvtiveNav('#')} className={activNav === '#' ? 'active' : ''}><IoHome /></a>
      <a href='#about' onClick={() => setAvtiveNav('#about')} className={activNav === '#about' ? 'active' : ''}><FaRegUser/></a>
      <a href='#skills' onClick={() => setAvtiveNav('#skills')} className={activNav === '#skills' ? 'active' : ''}><FaAlignJustify /></a>
      <a href='#portfolio' onClick={() => setAvtiveNav('#portfolio')} className={activNav === '#portfolio' ? 'active' : ''}><TbHexagonLetterP/></a>
      <a href='#contact' onClick={() => setAvtiveNav('#contact')} className={activNav === '#contact' ? 'active' : ''}><FaCommentAlt/></a>
    </nav>
  )
}

export default Nav
