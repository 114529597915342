import React from 'react'
import './header.scss'
import CTA from './CTA';
import ME from '../../assets/moaz-22.png'
import HeaderSocials from './headerSocials'

const Header = () => {
  return (
    <header>
      <div className='container header_container'>
        <h3>Hello I'm</h3>
        <h1>Moaz Nafa</h1>
        <h3 className='text_light'>Front-end Developer</h3>
        <CTA />
        <HeaderSocials />
        <div className='me'>
          <img className='img' src={ME} alt='Nafa'></img>
        </div>
          <a className='scroll_down' href='#contact'>Scroll Down</a>
      </div>
    </header>     
  )
}

export default Header
